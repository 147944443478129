import React from 'react';

import Layout from '../../components/Layout';
import { langPath } from '../../components/Nav';

import photo1 from '../../assets/images/granny/Elegantfoto-6A.jpg';
import photo2 from '../../assets/images/granny/Elegantfoto-9A.jpg';
import photo3 from '../../assets/images/granny/Elegantfoto-16A.jpg';
import photo4 from '../../assets/images/granny/Elegantfoto-22A.jpg';
import photo5 from '../../assets/images/granny/Elegantfoto-26A.jpg';
import photo6 from '../../assets/images/granny/Elegantfoto-28A.jpg';
import photo7 from '../../assets/images/granny/Elegantfoto-8247A.jpg';
import photo8 from '../../assets/images/granny/Elegantfoto-8255.jpg';
import photo9 from '../../assets/images/granny/Elegantfoto-8262.jpg';

import photo10 from '../../assets/images/granny/Elegantfoto-8268.jpg';
import photo11 from '../../assets/images/granny/Elegantfoto-8277.jpg';
import photo12 from '../../assets/images/granny/Elegantfoto-8284A.jpg';
import photo13 from '../../assets/images/granny/Elegantfoto-8285.jpg';
import photo14 from '../../assets/images/granny/Elegantfoto-8291.jpg';
import photo15 from '../../assets/images/granny/Elegantfoto-8294.jpg';

const GrannyProjectsPage = ({pageContext: {langKey}}) => (
  <Layout fullMenu {...{langKey}}>
    <section id="banner">
      <div className="inner">
        <h2>
          {langKey === 'en' && <> Granny flats projects</>}
          {langKey === 'vi' && <>Các dự án xây granny flat</>}
        </h2>

        <a href={langPath(langKey, '/projects')} className="special">
          {langKey === 'en' && <>See other projects</>}
          {langKey === 'vi' && <>Xem các dự án khác</>}
        </a>
      </div>
    </section>

    <section id="wrapper">
      <div className="wrapper">
        <div className="inner">
          <img alt="" width="100%" src={photo1} />
          <img alt="" width="100%" src={photo2} />
          <img alt="" width="100%" src={photo3} />
          <img alt="" width="100%" src={photo4} />
          <img alt="" width="100%" src={photo5} />
          <img alt="" width="100%" src={photo6} />
          <img alt="" width="100%" src={photo7} />
          <img alt="" width="100%" src={photo8} />
          <img alt="" width="100%" src={photo9} />

          <img alt="" width="100%" src={photo10} />
          <img alt="" width="100%" src={photo11} />
          <img alt="" width="100%" src={photo12} />
          <img alt="" width="100%" src={photo13} />
          <img alt="" width="100%" src={photo14} />
          <img alt="" width="100%" src={photo15} />
        </div>
      </div>
    </section>
  </Layout>
);

export default GrannyProjectsPage;
export { Head } from '../../components/Head';
